import SideModalContainer from "./style";
import {AiOutlineClose} from "react-icons/all";
import React, {useEffect, useRef, useState} from "react";
import PersonalInfo from "../../data/personal.info";

function ListenerForOnClickModalContent(ref, fnState, setContent) {
    if(typeof window !== "undefined") {
        window.addEventListener('click', function (e) {
            if (e.target.getAttribute('data-info')) {
                setContent(e.target.getAttribute('data-info'))
                fnState(true)
            }
        });
    }

}

function OnOpen(refContainer, duration) {
    const element = refContainer.current;
    if (duration)
        element.style.animationDuration = duration + "s !important";


    element.style.visibility = "visible";
    element.style.animationName = "backInRight";
}

function OnClose(refContainer, duration) {
    const element = refContainer.current;
    if (duration)
        element.style.animationDuration = duration + "s !important";

    element.style.visibility = "hidden";
    element.style.animationName = "backOutRight";

}

const SideModal = (props) => {
    const eRef = useRef(null)
    const containerRef = useRef(null)
    const [show, setShow] = useState(false)
    const [content, setContent] = useState("")
    useEffect(() => {
        if (props.show !== undefined)
            setShow(props.show)

        if (!show) {
            OnClose(containerRef)
        } else {
            OnOpen(containerRef)
        }

    }, [props.show, show]);

    ListenerForOnClickModalContent(eRef, setShow, setContent)
    return <SideModalContainer show={show} ref={containerRef}>
        <div className={'close'} tabIndex={0} onClick={() => setShow(false)} onKeyPress={(e)=> {
            if(e.key === "Enter"){
                setShow(false)
            }
        }} role={"button"}>
            <AiOutlineClose size={'100%'} color={"#fff"}/>
        </div>
        <div className={"container"} ref={eRef}>
            {PersonalInfo[content]}
        </div>
    </SideModalContainer>
}

export default SideModal
