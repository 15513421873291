import styled from "styled-components";
import EuEmPe from "../../assets/images/euempe_cut.png";
import EuTouca from "../../assets/images/eu_boina_gray.png";
import EuRiso from "../../assets/images/eu_riso_1.png";
import EuDefault from "../../assets/images/eu_default_gray.png";


const MainPresentation = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100%;
  height:auto;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: ZenKurenaido-Regular,sans-serif;
  flex-direction: column;
  text-transform: uppercase;
  padding-top: ${props => props.extraPadding ? props.extraPadding : 0};
`
const SpanIam = styled.span`
  line-height: 1;
  padding-right: 385px;
  color: #939393;
  transition: all 200ms linear;
  @media(max-width: 400px){
    padding-right: 200px;
  }
`
const HOne = styled.h1`
  @keyframes animHome {
    0% {
        transform: translate(0,0px);
    }
    20%{
        transform: translate(0px,6px);
    }
    40%{
        transform: translate(6px,0px);
    }
    60%{
        transform: translate(0px,-6px);
    }
    80% {
        transform: translate(-6px,0px);
    }
    100%{
        transform: translate(0,0px);
    }
}
  font-size: 5em;
  animation-name: animHome;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  text-align: center;
  transition: all 200ms linear;
  
  @media (max-width: 800px) {
    font-size: 3.9em !important;
  }
 
`
const PFullStackDev = styled.p`
  padding-left: 200px;
  line-height: 1;
    transition: all 200ms linear;
  text-transform: initial !important;
`
const EuToucaImg = styled.div`
  background-image: url(${EuTouca});
  background-repeat: no-repeat;
  background-size: cover;
  width: 550px;
  height: 100%;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 600ms linear;
  animation-name: showPhotoFade;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-fill-mode: both;
`
const EuDefaultImg = styled.div`
  background-image: url(${EuDefault});
  background-repeat: no-repeat;
  background-size: 86%;
  width: 516px;
  height: 100%;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 600ms linear;
  animation-name: showPhotoFade;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-fill-mode: both;
`
const EuRisoImg = styled.div`
  background-image: url(${EuRiso});
  background-repeat: no-repeat;
  background-size: 99%;
  width: 526px;
  height: 100%;
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 0;
    transition: all 600ms linear;
  animation-name: showPhotoFade;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-fill-mode: both;
`
const EuEmPeImg = styled.div`
  background-image: url(${EuEmPe});
  background-repeat: no-repeat;
  background-size: cover;
  width: 459px;
  height: 99%;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
    transition: all 600ms linear;
  animation-name: showPhotoFade;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-fill-mode: both;
`

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  & > h1 {
    text-align: center;
    text-shadow: 1px 2px 5px rgba(0,0,0,.22);
    margin-bottom: 10px;
  } 
`
const ContentInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
`

const H1 = styled.h1`
  text-align: center;
  font-size: 4em;
  text-shadow: 1px 2px 5px rgba(0,0,0,.22);
  margin-bottom: 20px;
  
  @media(max-width: 320px){
    font-size: 2em !important;
  }
`
const H2 = styled.h2`
  text-align: center;
  text-shadow: 1px 2px 5px rgba(0,0,0,.22);
  margin-bottom: 20px;
  padding: 0 20px;

  @media(max-width: 320px){
    font-size: 1.4em !important;
  }
`
const ArrowJump = styled.div`
  position: absolute;
  left: 50%;
  bottom: 10%;
  text-align: center;
  font-family: ZenKurenaido-Regular,sans-serif;
  line-height: 1;
  color: #fff;
`


export {
    ArrowJump,
    MainPresentation,
    EuEmPeImg,
    EuRisoImg,
    EuToucaImg,
    EuDefaultImg,
    HOne,
    PFullStackDev,
    SpanIam,
    InfoBox,
    ContentInfoBox,
    H1,H2
}
