import styled from 'styled-components'

const WorkIconContainer = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  border-radius: 4px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin: 0 20px;
  transition: all 180ms ease-in-out;
  & > .over {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor:pointer;
  }
  
  & > #content {
    width: 70%;
    height: 70%;
    margin: 0;
    position: relative;
      transition: all 180ms ease-in-out;
  } 
  
  &:hover > #content {
    transition: all 180ms ease-in-out;
    animation-name: tada;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    
  }
  @media(max-width: 500px){
    width: 70px;
    height: 70px;
    border-radius: 2px;
  }
  
  @media (min-width: 501px ) and (max-width: 800px){
    width: 170px;
    height: 170px;
    border-radius: 2px;
    margin: 0 12px;
  }
  
`

export default WorkIconContainer
