import React from "react";
import {
    AiFillHtml5,
    BsBootstrap,
    BsWordpress,
    DiCss3,
    DiJava,
    DiJqueryLogo,
    DiMongodb,
    DiMysql,
    DiReact,
    FaNodeJs,
    FaPhp,
    SiGatsby,
    SiGraphql,
    SiMicrosoftsqlserver,
    SiNestjs,
    SiNextdotjs,
    SiSocketdotio
} from "react-icons/all";
import styled from 'styled-components'

/*
<CommonContent>
    <div className={'title'}>
        < size={"3.5em"}/>
        <h1></h1>
    </div>
    <div className={'content'}>
        <i></i>
        <p></p>
    </div>
</CommonContent>

* */


const CommonContent = styled.div`
  font-family: ZenKurenaido-Regular, sans-serif;

  & > .title {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    & > h1 {
      margin-left: 20px;
    }

  }

  & > .content {
    display: flex;
    flex-direction: column;

    & > i, p, span {
      margin: 15px 0;
    }

    & > p {
      font-size: 15pt;
      margin: 4px 0;
      text-indent: 1.5em;
    }
  }
`

const Php = () => {
    return (
        <CommonContent>
            <div className={'title'}>
                <FaPhp size={'3.5em'} color={"#fff"}/>
                <h1>PHP</h1>
            </div>
            <div className={'content'}>
                <i>Ainda trás uma nostalgia, quando comecei o PHP ainda estava na sua versão 5 bem inicial em fase
                    Beta.</i>
                <p>Minha primeira linguagem de programação, inclusive a primeira estudada. Trabalho mais de 8 anos com
                    PHP, não digo mais de 14 anos porque passei a estudar diversas outras
                    linguagens após me especializar o suficiente. Resolvi expandir meu know how de linguagens de
                    servidores,
                    principalmente com a alta mudança constante de tecnologias.</p>
                <p>Mesmo o PHP sendo tão utilizado,
                    precisamos abrir o leque de opções.</p>
            </div>

        </CommonContent>
    )
}
const Java = () => {
    return (
        <CommonContent>
            <div className={'title'}>
                <DiJava size={"3.5em"}/>
                <h1>Oracle Java</h1>
            </div>
            <div className={'content'}>
                <i>Quem nunca estudou java na Faculdade não sabe o que é OOP! hahaha.</i>
                <p>Como o Java é uma linguagem compilada muito difundida e conhecida, acredito que pelo menos a
                    grande maioria já teve contato com a linguagem de programação Java.</p>
                <p>Comigo o "buraco" foi um pouco mais embaixo, pois com mais de 2 anos de trabalho em Java Web e
                    seus famosos Servlets, já produzi uma plataforma ERP completa para corretores de seguro de
                    saúde. Além de alguns apps menores para controle de veículos de grande porte, acredito que já da
                    para
                    me considerar um júnior hehehe.</p>
            </div>
        </CommonContent>
    )
}
const NodeJs = () => {
    return (
        <CommonContent>
            <div className={'title'}>
                <FaNodeJs size={"3.5em"}/>
                <h1>Node.JS</h1>
            </div>
            <div className={"content"}>
                <p>A linguagem EcmaScript para servidores em que eu mais tenho trabalhado ultimamente (falo desde 2019),
                    diversos projetos já foram criados e ainda estão em desenvolvimento utilizando servidores
                    Node.JS.</p>
                <p>Depois dá uma passadinha na <a href={'https://www.redcomet.com.br'}>RedComet</a>,
                    basicamente todos os serviços disponíveis lá são feitos
                    em Node utilizando várias tecnologias de bibliotecas e frameworks para nossa alegria.</p>
            </div>
        </CommonContent>
    )
}
const MongoDb = (_) => {
    return (<CommonContent>
        <div className={'title'}>
            <DiMongodb size={"3.5em"}/>
            <h1>MongoDB</h1>
        </div>
        <div className={'content'}>
            <i>NoSql fantástico para banco de dados baseado em documentos.</i>
            <p>Sem Muito o que falar além de elogios, um banco de dados que estudei juntamente com o node.js para o
                desenvolvimento
                de um projeto. O mesmo tempo que tenho em Node.JS também tenho em MongoDB.</p>
            <p>Trabalhei bastante com o mongo, no começo sofri um bocado em sua documentação e testes sobre o funcionamento de
                transações
                e suas peculiaridades com os replicasSets. </p>
            <p>As suas capped collections também são interessantes para sistemas com uma alta demanda de entrada e saída
                de dados rápidos.
                Mas a um preço, visto pelo próprio nome "capped", possui um limite de tamanho fixo, então tem que ser
                bem trabalhado.</p>
        </div>
    </CommonContent>)
}
const Mysql = () => {
    return (<CommonContent>
        <div className={'title'}>
            <DiMysql size={"3.5em"}/>
            <h1>MySQL</h1>
        </div>
        <div className={'content'}>
            <i>Considero como a mãe para os filhos que estão iniciando na programação e querem aprender sobre banco de
                dados de forma geral.
                Principalmente por ser SQL.</i>
            <p>Primeiro banco estudado a fio na época do PHP. Já vi de tudo ou quase tudo sobre o MYSQL e o que ele pode
                fazer. Não tão
                rápido como os NoSQL, mas não fica atrás se a sua regra de negócio não tem nada de extravagante demais.
                Basicamente também possuo de 8 a 10 anos de mysql,
                mesmo não realizando projetos em PHP, ainda continuei utilizando em projetos JAVA e Node.JS</p>
        </div>
    </CommonContent>)
}
const MsSql = () => {
    return (<CommonContent>
            <div className={'title'}>
                <SiMicrosoftsqlserver size={"3.5em"}/>
                <h1>MS SQL</h1>
            </div>
            <div className={'content'}>
                <p>Trabalhei cerca de 1 ano de forma intensiva com mssql para uma empresa que desejava montar um projeto
                    de cotação, compra e venda de materiais em larga
                    escala, utilizando o retorno direto de dados de notas fiscais de um sistema de terceiros para
                    análise de dados, então foi utilizado o mssql aproveitando o servidor já
                    existente na intranet da empresa para a solução.</p>
            </div>
        </CommonContent>
    )
}
const GraphQL = () => {
    return (<CommonContent>
            <div className={'title'}>
                <SiGraphql size={"3.5em"}/>
                <h1>GraphQL</h1>
            </div>
            <div className={'content'}>
                <i>Particularmente, acho a melhor linguagem de consulta ja criada.</i>
                <p> ja tenho cerca de 3 anos de experiência com o GraphQL e trabalho atualmente com a biblioteca para
                    projetos mais detalhados e pesados, visto que sua premissa é justamente diminuir a carga
                    de trabalho do servidor por buscar dados específicos do banco. Combina perfeitamente com bancos de
                    dados NOSQL, mas nada impede de utilizá-lo em SQL.</p>
                <p>A maioria dos projetos são desenvolvidos em Node.JS, mas também é possível com PHP ou outra
                    linguagens como JAVA Web, pois possui um schema tipado e configuração não vinculada a linguagem.
                    Apesar de que é importante salientar que o seu desempenho pode variar de acordo a linguagem usada,
                    mas com certeza nada comprometedor.</p>
            </div>
        </CommonContent>
    )
}
const SocketIO = () => {
    return (<CommonContent>
            <div className={'title'}>
                <SiSocketdotio size={"3.5em"}/>
                <h1>Socket.IO</h1>
            </div>
            <div className={'content'}>
                <p>Cerca de 1 ano utilizando o socket.io para desenvolvimento de uma interface de conversação para uma
                    intranet, é leve, prático e não precisa de muita burocracia para
                    funcionar. Claro que o Node.JS ajuda muito com as questões de dependências. Mas as vezes "enrosca"
                    quando o servidor usado é muito restritivo, o que pode causar dores de cabeça
                    se vc não sabe de onde realmente esta vindo o problema.</p>
            </div>
        </CommonContent>
    )
}
const NestJS = () => {
    return (<CommonContent>
        <div className={'title'}>
            <SiNestjs size={"3.5em"}/>
            <h1>Nest JS</h1>
        </div>
        <div className={'content'}>
            <i>Não confunda com Next.JS, que é uma biblioteca Front-end em React.</i>
            <p>2 anos trabalhando com essa belezura, framework de Back-end excelente para maioria das necessidades,
                claro que possui algumas chatices como
                invocação cíclica de módulos quando ambos se inter-relacionam por exemplo, e sim, ele lembra o angular
                quando se trata de módulos. </p>
            <p>
                Trabalhando direitinho você evita tais problemas e de quebra economiza um tempo maravilhoso, apesar dele
                executar tanto em JS quanto TS,
                ele funciona melhor em TypeScript devido ao poder dos decorators (é o mesmo que uma annotation em Java,
                te ajudei agora em ;) ).</p>
        </div>
    </CommonContent>)
}
const Bootstrap = () => {
    return (
        <CommonContent>
            <div className={'title'}>
                <BsBootstrap size={"3.5em"}/>
                <h1>Bootstrap</h1>
            </div>
            <div className={'content'}>
                <p>Cerca de 5 anos de bootstrap, não é muito para o tempo total da web, mas é o suficiente devido
                    a baixa complexidade da biblioteca de ui. É excelente para evitar reinventar a roda, ou reescrever
                    linhas CSS que se repete
                    por diversos sites produzidos, principalmente códigos de "reset" de aparência de estilo.</p>
                <p>É muito útil se você não esta procurando por uma interface inovadora, visto que, devido ao seu
                    padrão, é muito do mesmo o tempo todo. Mas
                    auxilia muito se o que esta sendo desenvolvido é uma interface de trabalho e não um site de
                    apresentação, devido ao seu "template" de interface muito comum
                    em diversos sites na web.</p>
            </div>
        </CommonContent>
    )
}
const Html5 = () => {
    return (
        <CommonContent>
            <div className={'title'}>
                <AiFillHtml5 size={"3.5em"}/>
                <h1>HTML 5</h1>
            </div>
            <div className={'content'}>
                <i>O que seria da web hoje sem o HTML 5 não? com certeza ainda estaríamos usando Flash Player e o
                    QuickTime para reprodução e animação de interfaces.</i>
                <p>Quando comecei a minha vida na programação o HTML 5 ainda não existia. Sim, estou ficando velho, mas
                    como diz o ditado, só não envelhece quem já morreu.</p>
                <p>E como eu estou vivinho da Silva, ou melhor do Santana, hehe, lá se vão uns 8 anos de HTML5 ou mais,
                    basicamente trabalho com HTML 5 desde o seu lançamento. </p>
            </div>
        </CommonContent>
    )
}
const Css3 = () => {
    return (<CommonContent>
        <div className={'title'}>
            <DiCss3 size={"3.5em"}/>
            <h1>CSS 3</h1>
        </div>
        <div className={'content'}>
            <i>E que venha o CSS 4!</i>
            <p>Semelhantemente ao Html 5, o CSS 3 ainda estava em testes e nem todos os navegadores suportavam todas as
                suas funções quando comecei a estudá-lo e trabalhar com ele.
                Isso não significa que eu sou o ancião que pegou todas essas tecnologias em seu pleno lançamento, mas
                como eu
                tenho entre 8 a 10 anos em contato com o CSS 3, eu digo que revolucionou o desenvolvimento de websites.
                Principalmente nas características de UX..</p>
            <p>Hoje em dia muita gente fala mal por causa das fraquezas em animação, como as animações de interface e
                sua famosa propriedade display que não pode ser animado. (apesar de ser
                óbvio o motivo por não faze-lo).</p>
            <p>Hummmm. Ficou curioso para saber o motivo? psshhh. é segredo!! XD</p>
        </div>
    </CommonContent>)
}

const Jquery = () => {
    return (
        <CommonContent>
            <div className={'title'}>
                <DiJqueryLogo size={"3.5em"}/>
                <h1>JQuery</h1>
            </div>
            <div className={'content'}>
                <i>O quebra galho de todo designer. Na verdade, é tão bom que eu costumo chamá-lo de quebra tronco!!</i>
                <p>Bom. Como eu tinha uma psicologia meio esquisita de querer usar somente o Vanilla JS (js nativo, sem
                    biblioteca), os meus conhecimentos
                    em Jquery são reduzidos, apenas 5 anos trabalhando com ele, também não é difícil e sua documentação
                    não perde para ninguém.</p>
                <p>Confesso que o JQuery me ajudou muito principalmente na questão de animação de interface e event
                    listeners, mas ficar viciado nele te causa problemas
                    na hora de mexer na massa nativa do Ecmascript (Javascript para os mais chegados).</p>
            </div>
        </CommonContent>
    )
}

const ReactInfo = () => {
    return (<CommonContent>
        <div className={'title'}>
            <DiReact size={"3.5em"}/>
            <h1>React</h1>
        </div>
        <div className={'content'}>
            <i>Sendo franco? Minha biblioteca preferida. Também da para desenvolver Apps com ele cara! Ele é tipo tudo
                em 1.</i>
            <p>Cerca de 2 anos trabalhando e estudando React. Todos os sites da RedComet são feitos em React. Nem todos
                estão disponíveis ainda, pois estão em desenvolvimento.</p>
            <p>Bom, ele é excelente, mas realmente existe problemas com
                interface lag e seus estados, coisa que é bem chatinho e as vezes te deixa biruta, se voce não gosta de
                escovar bit, vai ter q começar meu consagrado.</p>
        </div>
    </CommonContent>)
}

const Gatsby = () => {
    return (<CommonContent>
            <div className={'title'}>
                <SiGatsby size={"3.5em"}/>
                <h1>Gatsby</h1>
            </div>
            <div className={'content'}>
                <i>Mal conheço e já considero Pakas!!!</i>
                <p>O meu contato com esse framework ainda é um pouco raso, mais o trabalho com ele é excelente e prático
                    para sites de nível estático (não estou falando de sites básicos em HTMl puro).</p>
                <p>Inclusive, este site que a vossa senhoria está vendo é feito nele. Já conta como uma experiência não?
                    hahaha.
                    Duvidando? Da uma olhadinha no Código-Fonte, só não vale copiar.</p>

            </div>
        </CommonContent>
    )
}

const NextJs = () => {
    return (<CommonContent>
        <div className={'title'}>
            <SiNextdotjs size={"3.5em"}/>
            <h1>Next.JS</h1>
        </div>
        <div className={'content'}>
            <i>Lembre-se que esse framework é dependente de uma biblioteca, que é o React. Logo, voce tem que possuir
                conhecimento em React ;)</i>
            <p>Next.JS é semelhante ao Gatsby, bem mais detalhado, esquemas de rotas por arquivo, definições de
                middleware em front-end, tenho cerca de 6 meses trabalhando com ele,
                o framework não possui pacotes de componentes, tem capacidade de builds em SSR,
                com alguns nuances, também possui processamento de imagem, o que já te agiliza o trabalho e ajuda na
                redução de consumo de CDN.
            </p>
            <p>A ideia do framework é fornecer um pacote para construção de sites servless, ou seja, sites interativos
                em que não depende de um servidor de
                serviço externo ou end-point API, possui suporte a obtenção REQ/RES das requisições HTTP(S) e pode
                realizar requisições se construído como um servidor.</p>
            <p>Tem a capacidade de montar pacotes (build) em static-site, mas obviamente, perde algumas vantagens como a
                funcionalidade do middleware, ja que ele nao é iniciado
                por um script e sim servido estaticamente!</p>
        </div>
    </CommonContent>)
}


const Wordpress = () => {
    return (<CommonContent>
        <div className={'title'}>
            <BsWordpress size={"3.5em"}/>
            <h1>Wordpress</h1>
        </div>
        <div className={'content'}>
            <p>O CMS mais usado da web, e não é brincadeira, meu contato é de apenas 1 ano, pois o meu foco sempre foi
                projetos
                personalizados e profissionais, mais já construí um website
                que atualmente estou gerenciamento conteúdo e atualizando mensalmente.</p>
            <p>Particularmente, eu acho o gerenciamento de templates bem prático, com a utilização de short-codes e sua
                interface para leigos, para mim não foi um problema.</p>
            <p>Mais definitivamente é um framework para blogs e é isso, voce pode ate ter um E-commerce, mas não deseje
                demais, pois, a expansão é limitada a sua construção. Mais
                se voce quer um site que saia rápido e com uma gestão de conteúdo já pronto. Pode me chamar que a gente
                monta tranquilo.</p>
        </div>
    </CommonContent>)
}


const PersonalInfo = {
    php: <Php/>,
    java: <Java/>,
    nodejs: <NodeJs/>,
    mongodb: <MongoDb/>,
    mysql: <Mysql/>,
    mssql: <MsSql/>,
    graphql: <GraphQL/>,
    socketio: <SocketIO/>,
    nestjs: <NestJS/>,
    bootstrap: <Bootstrap/>,
    html5: <Html5/>,
    css3: <Css3/>,
    jquery: <Jquery/>,
    react: <ReactInfo/>,
    gatsby: <Gatsby/>,
    nextjs: <NextJs/>,
    wordpress: <Wordpress/>
}
export default PersonalInfo
