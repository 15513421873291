import styled from 'styled-components'

const SideModalContainer = styled.div`
  box-shadow: -2px 1px 6px rgba(0,0,0,.12);
  display: flex;
  height: 100%;
  width: 45%;
  position: absolute;
  background: #242424;
  padding: 20px;
  top: 0;
  right: 0;
  z-index: 999999;
  color: #fff;
  transition: all 300ms linear;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-duration: 1s;
  opacity: 0;
  visibility: hidden;
  box-sizing: border-box;
  
  @keyframes rotate{
      from {
        transform: rotateZ(0deg);
      }
      to {
        transform: rotateZ(360deg);
      }
  }
  & > .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    cursor: pointer;
    height: 20px;
    margin: 0;
    padding: 0;
    transition: all 200ms linear;

    
    &:hover {
      transition: all 200ms linear;
      animation-name: rotate;
      animation-duration: 1s;

    }
    z-index: 2;
  }

  & .container {
    padding: 5px;
    text-align: justify;
  }
  
  @media(max-width: 500px) {
    width: 100%;
    padding: 0;
    
  }
  
`

export default SideModalContainer
