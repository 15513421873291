import * as React from 'react'
import GlobalLayout from "../layout/global";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

import {
    ArrowJump,
    ContentInfoBox,
    EuEmPeImg,
    H1,
    H2,
    HOne,
    InfoBox,
    MainPresentation,
    PFullStackDev,
    SpanIam
} from "../components/pages/home.styles";
import {SiMicrosoftsqlserver, SiNestjs, SiNextdotjs, SiPhp, SiSocketdotio} from "react-icons/si";
import {GrGatsbyjs, GrGraphQl,} from 'react-icons/gr'
import {AiFillHtml5} from 'react-icons/ai'
import {BsBootstrap, } from 'react-icons/bs'
import {IoIosArrowDown} from 'react-icons/io'
import {DiCss3, DiJava, DiJqueryLogo, DiMongodb, DiMysql, DiNodejs, DiReact, DiWordpress,} from 'react-icons/di'
import WorkIcon from "../components/WorkIcon";
import Flip from "react-reveal/Flip";
import SideModal from "../components/SideModal";
import Jump from "react-reveal/Jump";


const IndexPage = ({location}) => {
    return (
        <GlobalLayout pageTitle={"Kevin Santana FullStack Dev"} location={location}>
            <SideModal/>
            <EuEmPeImg/>
            <MainPresentation>
                <Zoom opposite>
                    <SpanIam>Eu sou</SpanIam>
                    <HOne>Kevin Santana</HOne>
                    <PFullStackDev>FullStack Dev.</PFullStackDev>
                </Zoom>

            </MainPresentation>
            <Jump delay={1200} count={'infinite'}>
                <ArrowJump>
                    <p>Mais</p>
                    <IoIosArrowDown size={24}/>

                </ArrowJump>
            </Jump>

            <MainPresentation extraPadding={'60px'}>
                <Fade top>
                    <H1>Especialidades Back-End</H1>
                </Fade>
                <Fade left>
                    <InfoBox>
                        <H2>Linguagem para Servidores</H2>
                        <Fade up cascade>
                            <ContentInfoBox>
                                <WorkIcon icon={SiPhp} data-info={"php"}/>
                                <WorkIcon icon={DiNodejs} data-info={"nodejs"}/>
                                <WorkIcon icon={DiJava} data-info={"java"}/>
                            </ContentInfoBox>
                        </Fade>
                    </InfoBox>
                </Fade>
                <Fade right delay={300}>
                    <InfoBox>
                        <H2>Banco de dados</H2>
                        <Flip bottom cascade delay={400}>
                            <ContentInfoBox>
                                <WorkIcon icon={DiMongodb} data-info={"mongodb"}/>
                                <WorkIcon icon={DiMysql} data-info={"mysql"}/>
                                <WorkIcon icon={SiMicrosoftsqlserver} data-info={"mssql"}/>
                            </ContentInfoBox>
                        </Flip>
                    </InfoBox>
                </Fade>
                <Fade right delay={300}>
                    <InfoBox>
                        <H2>Tecnologias de Back-End</H2>
                        <Flip bottom cascade delay={400}>
                            <ContentInfoBox>
                                <WorkIcon icon={GrGraphQl} data-info={'graphql'}/>
                                <WorkIcon icon={SiSocketdotio} data-info={"socketio"}/>
                                <WorkIcon icon={SiNestjs} data-info={"nestjs"}/>
                            </ContentInfoBox>
                        </Flip>
                    </InfoBox>
                </Fade>
            </MainPresentation>

            <MainPresentation extraPadding={'60px'}>
                <Fade top>
                    <H1>Especialidades Front-End</H1>
                </Fade>
                <Fade left>
                    <InfoBox>
                        <H2>Tecnologias Básicas</H2>
                        <Fade up cascade>
                            <ContentInfoBox>
                                <WorkIcon icon={BsBootstrap} data-info={"bootstrap"}/>
                                <WorkIcon icon={AiFillHtml5} data-info={'html5'}/>
                                <WorkIcon icon={DiCss3} data-info={'css3'}/>
                            </ContentInfoBox>
                        </Fade>
                    </InfoBox>
                </Fade>
                <Fade right delay={300}>
                    <InfoBox>
                        <H2>Tecnologias Em Bibliotecas</H2>
                        <Flip bottom cascade delay={400}>
                            <ContentInfoBox>
                                <WorkIcon icon={DiJqueryLogo} data-info={'jquery'}/>
                                <WorkIcon icon={DiReact} data-info={'react'}/>
                            </ContentInfoBox>
                        </Flip>
                    </InfoBox>
                </Fade>
                <Fade right delay={300}>
                    <InfoBox>
                        <H2>Tecnologias de Framework</H2>
                        <Flip bottom cascade delay={400}>
                            <ContentInfoBox>
                                <WorkIcon icon={GrGatsbyjs} data-info={'gatsby'}/>
                                <WorkIcon icon={SiNextdotjs} data-info={'nextjs'}/>
                                <WorkIcon icon={DiWordpress} data-info={'wordpress'}/>
                            </ContentInfoBox>
                        </Flip>
                    </InfoBox>
                </Fade>
            </MainPresentation>
        </GlobalLayout>
    )
}

export default IndexPage
