import WorkIconContainer from "./style";
import React from "react";


const WorkIcon = (props) => {
    return <WorkIconContainer {...props}>
        <div id={'content'}>
            <props.icon size={'100%'}/>
        </div>
        <div className={"over"} {...props}/>
    </WorkIconContainer >
}

export default WorkIcon
